import React, { useState } from "react";
import RecipientSelector from "./RecipientSelector";
import SendingStackManager from "./SendingStackManager";
import MessageLogs from "./MessageLogs";
import SendMessage from "./SendMessage";
import AttendanceManager from "./AttendanceManager";
import BirthdayManager from "./BirthdayManager";
import NewComerManager from "./NewComerManager";
import "./MessageHomepage.css"; // Import the CSS file
import AdminHomepage from "../UserHandling/AdminHomepage";

function MessageHomepage() {
  const [activeTab, setActiveTab] = useState("RecipientSelector");

  const renderTabContent = () => {
    switch (activeTab) {
      case "RecipientSelector":
        return <RecipientSelector />;
      case "SendingStackManager":
        return <SendingStackManager />;
      case "MessageLogs":
        return <MessageLogs />;
      case "SendMessage":
        return <SendMessage />;
      case "AttendanceManager":
        return <AttendanceManager />;
      case "BirthdayManager":
        return <BirthdayManager />;
      case "NewComerManager":
        return <NewComerManager />;
      case "AdminHomepage":
        return <AdminHomepage/>;
      default:
        return <RecipientSelector />;
    }
  };

  return (
    <div className="message-homepage">
      <div className="tab-buttons">
        {["RecipientSelector", "SendingStackManager", "MessageLogs", "SendMessage", "AttendanceManager", "BirthdayManager", "NewComerManager", "AdminHomepage"].map(tab => (
          <button
            key={tab}
            className={activeTab === tab ? "active" : ""}
            onClick={() => setActiveTab(tab)}
          >
            {tab.replace(/([A-Z])/g, ' $1')} {/* Adds space before capital letters */}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
}

export default MessageHomepage;
