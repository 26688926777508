import React from 'react';
import './IntroSection.css';

const IntroSection = () => (
  <section className="intro">
    <h1>Welcome to RCCG JESUS PEOPLE'S PARISH DAGENHAM</h1>
    <p>Join us in worship and fellowship. We're glad to have you here.</p>
  </section>
);

export default IntroSection;
