import React, {useEffect, useState} from "react";
import {onAuthStateChanged, signOut} from "firebase/auth";
import {ref, set, get} from "firebase/database";
import {auth, database} from "../../firebase";
import SignUpPage from "./SignUpPage";
import LoginHomepage from "../Homepage/LoginHomepage";

var isLoginedIn = false;

const AuthDetails =  () => {
    const [authUser,setAuthUser] = useState(null);



    useEffect(() => {
        const listen = onAuthStateChanged(auth, async (user) => {
          if (user) {
            // User is signed in
            setAuthUser(user);
    
            // Check if the user is already in the Realtime Database
            const userRef = ref(database, `users/${user.uid}`);
            const snapshot = await get(userRef);
    
            if (!snapshot.exists()) {
              // If user does not exist in the Realtime Database, add them
              await set(userRef, {
                email: user.email,
                roles: {
                  admin: false,
                  messageteam: false,
                  annoucementteam: false,
                  choirteam: false,
                  usherteam: false,
                  prayerteam: false,
                  teacher: false,
                  member: true,
                },
              });
            } else {
              // If user exists, you might want to fetch their roles here
              const userData = snapshot.val();
              setAuthUser(prev => ({
                ...prev,
                roles: userData.roles, // Assuming roles are saved here
              }));
            }
          } else {
            setAuthUser(null);
          }
        });
    
        return () => {
          listen();
        };
      }, []);
    

    isLoginedIn = authUser !== null ? true : false;

    const userSignOut = () => {
        signOut(auth).then(() => {
            console.log('sign out successful')
        }).catch(error => console.log(error));
    }

    return(
            <div>
                {
                    authUser ?
                <>
                    <LoginHomepage authUser={authUser}/>
                    {/*
                    <AdminDashboard/>
                    <p>'Signed In as {authUser.email}' </p>
                    */}
                    
                    <button onClick={userSignOut}>Sign Out</button>
                </>
                :
                <>
                <SignUpPage/>
                {/*
                <p> Signed Out </p>
                */}
                </>
                }
            </div>
    )
}

export default AuthDetails;
export var isLoginedIn;
export var authUser;