import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, deleteDoc, addDoc, doc } from 'firebase/firestore';
import { firestore, storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import './AdminSlides.css';

function AdminSlides() {
  const [slides, setSlides] = useState([]);
  const [newSlide, setNewSlide] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  
  // Fetch existing slides from Firestore
  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'announcement_slides'));
        const slideData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSlides(slideData);
      } catch (error) {
        console.error("Error fetching slides:", error);
      }
    };

    fetchSlides();
  }, []);

  // Toggle slide visibility
  const toggleVisibility = async (slideId, currentVisibility) => {
    try {
      const slideRef = doc(firestore, 'announcement_slides', slideId);
      await updateDoc(slideRef, { visible: !currentVisibility });
      setSlides(prevSlides =>
        prevSlides.map(slide =>
          slide.id === slideId ? { ...slide, visible: !currentVisibility } : slide
        )
      );
    } catch (error) {
      console.error("Error toggling visibility:", error);
    }
  };

  // Add a new slide
  const addSlide = async () => {
    if (!newSlide || !newTitle) return;

    try {
      // Upload image to Firebase Storage
      const storageRef = ref(storage, `announcement_slides/${newSlide.name}`);
      await uploadBytes(storageRef, newSlide);
      const url = await getDownloadURL(storageRef);

      // Add slide metadata to Firestore
      const newDoc = await addDoc(collection(firestore, 'announcement_slides'), {
        url,
        title: newTitle,
        visible: true,
        order: slides.length + 1,
      });

      // Update state and reset inputs
      setSlides([...slides, { id: newDoc.id, url, title: newTitle, visible: true, order: slides.length + 1 }]);
      setNewSlide(null);
      setNewTitle('');
    } catch (error) {
      console.error("Error adding slide:", error);
    }
  };

  // Delete a slide
  const deleteSlide = async (slideId, imageUrl) => {
    const slideRef = doc(firestore, 'announcement_slides', slideId);
    const imageRef = ref(storage, imageUrl);
  
    // Attempt to delete the image from Firebase Storage
    try {
      await deleteObject(imageRef);
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        console.warn(`Image not found in storage: ${imageUrl}. Proceeding to delete Firestore document.`);
      } else {
        console.error("Error deleting image from storage:", error);
      }
    }
  
    // Attempt to delete the Firestore document regardless of the image deletion outcome
    try {
      await deleteDoc(slideRef);
    } catch (error) {
      console.error("Error deleting slide document from Firestore:", error);
    }
  
    // Update state to remove slide from view
    setSlides(prevSlides => prevSlides.filter(slide => slide.id !== slideId));
  };
  

  // Edit slide title
  const editSlide = async (slideId, newTitle) => {
    try {
      const slideRef = doc(firestore, 'announcement_slides', slideId);
      await updateDoc(slideRef, { title: newTitle });
      setSlides(prevSlides =>
        prevSlides.map(slide => (slide.id === slideId ? { ...slide, title: newTitle } : slide))
      );
    } catch (error) {
      console.error("Error editing slide title:", error);
    }
  };

  return (
    <div className="admin-slides">
      <h1>Manage Announcement Slides</h1>

      {/* Add New Slide */}
      <div className="add-slide-form">
        <input
          type="file"
          onChange={(e) => setNewSlide(e.target.files[0])}
          accept="image/*"
        />
        <input
          type="text"
          placeholder="Slide Title"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
        />
        <button onClick={addSlide}>Add Slide</button>
      </div>

      {/* Slide List */}
      <ul>
        {slides.map(slide => (
          <li key={slide.id} className="slide-item">
            {slide.url ? (
              <img 
                src={slide.url} 
                alt={slide.title} 
                width="100" 
                onError={(e) => e.target.style.display = 'none'} // Hide if image fails to load
              />
            ) : (
              <span>No image available</span>
            )}
            <input
              type="text"
              defaultValue={slide.title}
              onBlur={(e) => editSlide(slide.id, e.target.value)}
            />
            <button onClick={() => toggleVisibility(slide.id, slide.visible)}>
              {slide.visible ? 'Hide' : 'Show'}
            </button>
            <button onClick={() => deleteSlide(slide.id, slide.url)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminSlides;
