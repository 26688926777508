import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { database } from '../../firebase'; // Importing Firebase database
import { ref, set, get } from 'firebase/database'; // Firebase Realtime Database methods

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [emailConfirm, setEmailConfirm] = useState('');
    const [pwd, setPwd] = useState('');
    const [pwdConfirm, setPwdConfirm] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [roles, setRoles] = useState([]); // State for multiple roles
    const [availableRoles, setAvailableRoles] = useState([]); // Available roles fetched from Firebase
    const [errorMessage, setErrorMessage] = useState(''); // State for error messages
    const [showPasswords, setShowPasswords] = useState(false); // State to track password visibility

    useEffect(() => {
        // Fetch available roles from Firebase
        const fetchRoles = async () => {
            const rolesRef = ref(database, 'roles');
            const snapshot = await get(rolesRef);
            const rolesData = snapshot.val();
            if (rolesData) {
                setAvailableRoles(Object.keys(rolesData).map(key => ({ id: key, name: key })));
            }
        };
        fetchRoles();
    }, []);

    const handleRoleChange = (role) => {
        // Add/remove roles from the selected list
        setRoles(prev =>
            prev.includes(role) ? prev.filter(r => r !== role) : [...prev, role]
        );
    };

    const signUp = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Reset error message at the beginning of sign up

        if (pwd !== pwdConfirm) {
            setErrorMessage("Passwords don't match");
            return;
        }

        if (email !== emailConfirm) {
            setErrorMessage("Emails don't match");
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, pwd);
            const userId = userCredential.user.uid;

            // Structure roles with status as 'pending'
            const rolesWithStatus = roles.map(role => ({
                name: role,
                status: 'pending'
            }));

            // Save the additional data to Firebase
            const userRef = ref(database, `users/${userId}`);
            await set(userRef, {
                firstName,
                lastName,
                email: email,
                roles: {
                  admin: false,
                  worker:false,
                  messageteam: false,
                  annoucementteam: false,
                  choirteam: false,
                  usherteam: false,
                  prayerteam: false,
                  teacher: false,
                  mensmember:false,
                  womensmember:false,
                  children: false,
                  member: true,
                },
              });

            console.log('User created and data saved:', userCredential);
        } catch (error) {
            setErrorMessage('Error creating user: ' + error.message); // Display error message
        }
    };

    return (
        <div className='sign-up-container'>
            <form onSubmit={signUp}>
                <h1>Create an Account</h1>
                <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Re-enter your email"
                    value={emailConfirm}
                    onChange={(e) => setEmailConfirm(e.target.value)}
                />
                <input
                    type={showPasswords ? "text" : "password"} // Toggle password visibility for both
                    placeholder="Enter your password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                />
                <input
                    type={showPasswords ? "text" : "password"} // Toggle confirm password visibility for both
                    placeholder="Re-enter your password"
                    value={pwdConfirm}
                    onChange={(e) => setPwdConfirm(e.target.value)}
                />
                <div>
                    <input
                        type="checkbox"
                        checked={showPasswords}
                        onChange={() => setShowPasswords(prev => !prev)} // Toggle showPasswords state
                    />
                    <label>Show Passwords</label> {/* Checkbox to show/hide passwords */}
                </div>
                <div>
                    <h3>Select Roles:</h3>
                    {availableRoles.map(role => (
                        <div key={role.id}>
                            <input
                                type="checkbox"
                                checked={roles.includes(role.name)}
                                onChange={() => handleRoleChange(role.name)}
                            />
                            <label>{role.name}</label>
                        </div>
                    ))}
                </div>
                <button type="submit">Sign Up</button>
                {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message */}
            </form>
        </div>
    );
};

export default SignUp;
