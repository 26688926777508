import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { firestore as database } from '../../firebase'; // Ensure this imports your Firestore instance
import './SendMessage.css'; // Import the CSS file

const SendMessage = () => {
  const [recipientName, setRecipientName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [messageText, setMessageText] = useState('');
  const [sendingStatus, setSendingStatus] = useState('');

  const handleSendMessage = async () => {
    // Ensure the input data is valid
    if (!recipientName || !phoneNumber || !messageText) {
      setSendingStatus('Please fill out all fields.');
      return;
    }

    // Prepare recipient data
    const nameParts = recipientName.split(' ');
    const recipient = {
      first_name: nameParts[0] || '',
      last_name: nameParts[1] || '',
      phone_number: phoneNumber,
    };

    // Add message to Firestore 'sending_stack'
    try {
      const sendingStackCollection = collection(database, 'sending_stack');
      await addDoc(sendingStackCollection, {
        text: messageText,
        member: recipient,
        timestamp: new Date().toISOString(),
      });

      setSendingStatus('Message successfully added to the sending stack.');
      // Reset form fields
      setRecipientName('');
      setPhoneNumber('');
      setMessageText('');
    } catch (error) {
      console.error("Error sending message:", error);
      setSendingStatus('Error sending message. Please try again.');
    }
  };

  return (
    <div className="send-message-container">
      <h2>Send Message</h2>
      <p>This form allows you to send a message to people not in the members list.</p>
      <form className="send-message-form">
        <div className="form-group">
          <label>
            Recipient Name:
            <input
              type="text"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
              placeholder="First and Last Name"
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Phone Number:
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Message:
            <textarea
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              placeholder="Type your message here"
            />
          </label>
        </div>
        <button type="button" className="send-message-button" onClick={handleSendMessage}>
          Send Message
        </button>
      </form>
      {sendingStatus && <p className="sending-status">{sendingStatus}</p>}
    </div>
  );
};

export default SendMessage;
