import React, { useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { database } from '../../firebase'; // Firebase database

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to track password visibility
    const [error, setError] = useState(''); // State for error messages
    const navigate = useNavigate();

    const signIn = async (e) => {
        e.preventDefault();
        setError(''); // Reset the error state

        try {
            // Sign in using email and password
            const userCredential = await signInWithEmailAndPassword(auth, email, pwd);
            const user = userCredential.user;

            // Fetch user roles from the database
            const userRef = ref(database, `users/${user.uid}`);
            const snapshot = await get(userRef);

            if (snapshot.exists()) {
                const userData = snapshot.val();
                const userRoles = userData.roles || [];

                // Redirect based on the user's roles
                if (userRoles.includes('admin')) {
                    navigate('/admin'); // Redirect to Admin Dashboard
                } else if (userRoles.includes('user')) {
                    navigate('/dashboard'); // Redirect to User Dashboard
                } else {
                    setError('You do not have the required permissions to access the dashboard.');
                }
            } else {
                setError('User data not found.');
            }
        } catch (error) {
            setError('Login failed. Please check your email and password.'); // Display error message
        }
    };

    return (
        <div className='sign-in-container'>
            <form onSubmit={signIn}>
                <h1>Log In</h1>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="password-container">
                    <input
                        type={showPassword ? "text" : "password"} // Toggle password visibility
                        placeholder="Enter your password"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                    />
                    <label>
                        <input
                            type="checkbox"
                            checked={showPassword}
                            onChange={() => setShowPassword(!showPassword)} // Toggle show password state
                        />
                        Show Password
                    </label>
                </div>
                <button type="submit">Log in</button>
                <p>
                    Forgot your password? <a href="/forgot-password">Reset it here</a>
                </p>
                {error && <p className="error-message">{error}</p>} {/* Display error message */}
            </form>
        </div>
    );
};

export default SignIn;
