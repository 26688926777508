import React, { useState } from 'react';
import UserMenu from './UserMenu';
import ManageUserRoles from './ManageUserRoles';
import AdminAnnouncements from '../Announcements/AdminAnnouncements';
import AdminControl from '../Admin/AdminControl'; // Import your AdminControl component

const AdminHomepage = () => {
  const [activeTab, setActiveTab] = useState('userMenu'); // State to track the active tab

  const renderTabContent = () => {
    switch (activeTab) {
      case 'userMenu':
        return <UserMenu />;
      case 'manageUserRoles':
        return <ManageUserRoles />;
      case 'adminAnnouncements':
        return <AdminAnnouncements />;
      case 'adminControl': // Add case for AdminControl
        return <AdminControl />;
      default:
        return <UserMenu />;
    }
  };

  return (
    <div className="admin-homepage">
      <div className="tab-container">
        <button
          onClick={() => setActiveTab('userMenu')}
          className={activeTab === 'userMenu' ? 'active-tab' : ''}
        >
          User Menu
        </button>
        <button
          onClick={() => setActiveTab('manageUserRoles')}
          className={activeTab === 'manageUserRoles' ? 'active-tab' : ''}
        >
          Manage User Roles
        </button>
        <button
          onClick={() => setActiveTab('adminAnnouncements')}
          className={activeTab === 'adminAnnouncements' ? 'active-tab' : ''}
        >
          Announcements
        </button>
        <button
          onClick={() => setActiveTab('adminControl')} // New button for Admin Control
          className={activeTab === 'adminControl' ? 'active-tab' : ''}
        >
          Admin Control
        </button>
      </div>
      <div className="tab-content">
        {renderTabContent()} {/* Render the content based on the active tab */}
      </div>
    </div>
  );
};

export default AdminHomepage;
