import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { firestore as database } from '../../firebase';
import './RecipientSelector.css';

const RecipientSelector = () => {
  const [recipients, setRecipients] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState(new Set());
  const [messageTemplate, setMessageTemplate] = useState('');
  const [preview, setPreview] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterTitle, setFilterTitle] = useState('');

  // Fetch recipients from Firestore
  useEffect(() => {
    const fetchRecipients = async () => {
      try {
        const snapshot = await getDocs(collection(database, 'members'));
        const recipientsArray = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(recipient => recipient.phone_number); // Filter out recipients without phone numbers
        setRecipients(recipientsArray);
      } catch (error) {
        console.error("Error fetching recipients:", error);
      }
    };

    fetchRecipients();
  }, []);

  // Toggle individual recipient selection
  const handleSelectRecipient = (id) => {
    setSelectedRecipients(prevSelected => {
      const updatedSelection = new Set(prevSelected);
      updatedSelection.has(id) ? updatedSelection.delete(id) : updatedSelection.add(id);
      return updatedSelection;
    });
  };

  // Select or deselect all recipients
  const handleSelectAll = () => {
    setSelectedRecipients(selectedRecipients.size === recipients.length ? new Set() : new Set(recipients.map(r => r.id)));
  };

  // Preview customization for male and female placeholders
  const handlePreview = (gender) => {
    const message = messageTemplate
      .replace('@firstname', gender === "female" ? "Jessica" : "John")
      .replace('@lastname', gender === "female" ? "Smith" : "Doe")
      .replace('@title', gender === "female" ? "Sis" : "Bro");
    setPreview(message);
  };

  // Send customized messages to selected recipients
  const sendMessages = async () => {
    if (selectedRecipients.size > 1) {
      const confirmSend = window.confirm(`You are about to send messages to ${selectedRecipients.size} recipients. Do you want to continue?`);
      if (!confirmSend) return;
    }

    for (const id of selectedRecipients) {
      const recipient = recipients.find(r => r.id === id);
      const personalizedMessage = messageTemplate
        .replace('@firstname', recipient.first_name)
        .replace('@lastname', recipient.last_name)
        .replace('@title', recipient.title);

      try {
        await addDoc(collection(database, 'sending_stack'), {
          text: personalizedMessage,
          member: {
            first_name: recipient.first_name,
            last_name: recipient.last_name,
            phone_number: recipient.phone_number,
            title: recipient.title,
            DOB: recipient.DOB,
          },
          timestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error("Error adding message to sending stack:", error);
      }
    }

    // Reset state after sending messages
    setMessageTemplate('');
    setPreview('');
    setSelectedRecipients(new Set());
    alert('Messages sent successfully!');
  };

  // Filtered recipients based on search query and title filter
  const filteredRecipients = recipients.filter((recipient) => {
    const fullName = `${recipient.first_name} ${recipient.last_name}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase()) && (!filterTitle || recipient.title === filterTitle);
  });

  // Extract unique titles for dropdown filter
  const uniqueTitles = [...new Set(recipients.map(recipient => recipient.title))];

  return (
    <div className="container">
      <h2>Select Recipients</h2>

      {/* Search and Filter Inputs */}
      <input
        type="text"
        placeholder="Search by full name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <select value={filterTitle} onChange={(e) => setFilterTitle(e.target.value)}>
        <option value="">All Titles</option>
        {uniqueTitles.map(title => (
          <option key={title} value={title}>{title}</option>
        ))}
      </select>

      {/* Message Template Input */}
      <textarea
        placeholder="Message Template"
        value={messageTemplate}
        onChange={(e) => setMessageTemplate(e.target.value)}
      />

      {/* Action Buttons */}
      <div className="actions">
        <button onClick={handleSelectAll}>
          {selectedRecipients.size === recipients.length ? 'Deselect All' : 'Select All'}
        </button>
        <button onClick={sendMessages}>Send Messages</button>
        <button onClick={() => handlePreview("male")}>Male Preview</button>
        <button onClick={() => handlePreview("female")}>Female Preview</button>
      </div>

      <p>You can customize messages using @firstname, @lastname, and @title. Test it out!</p>
      {preview && <p className="preview"><strong>Preview:</strong> {preview}</p>}

      {/* Recipients List */}
      <ul className="recipients-list">
        {filteredRecipients.map(recipient => (
          <li key={recipient.id}>
            <label>
              <input
                type="checkbox"
                checked={selectedRecipients.has(recipient.id)}
                onChange={() => handleSelectRecipient(recipient.id)}
              />
              {`${recipient.title} ${recipient.first_name} ${recipient.last_name} - ${recipient.phone_number}`}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecipientSelector;
