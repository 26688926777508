import React from 'react';
import './Announcements.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import InslideAnnouncements from './InslideAccountments';

function Announcements() {
  return (
    <div className="announcements-page">
      <h1>Announcements</h1>
      <InslideAnnouncements/>
    </div>
  );
}

export default Announcements;
