import React from 'react';
import './DonateSection.css';
import { Link } from 'react-router-dom';

const DonateSection = () => (
  <section id="donate" className="donate-section">
    <h2>Support Our Ministry</h2>
    <p>Your support helps us continue our mission. Thank you for your generosity.</p>
    <Link to="/donation">
      <button className="donate-button">Donate</button>
    </Link>

  </section>
);

export default DonateSection;
