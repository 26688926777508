import React from 'react';
import { Link } from 'react-router-dom';
import logo from './Assets/RCCG_logo.png'; // Update the path to your logo
import "./Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={logo} alt="RCCG Logo" className="footer-logo" />
        <p className="footer-text">
          Jesus People's Parish &copy; {currentYear}. All rights reserved. 
          Registered Charity (1088614) and Company limited by guarantee in England. 
          <Link to="/privacy-policy" className="privacy-link">Privacy Policy</Link>
        </p>
      </div>
      <p className="developer-info">
        Developed by <a href="https://jideoyelayo.com" target="_blank" rel="noopener noreferrer">ME</a>
      </p>
    </footer>
  );
}

export default Footer;
