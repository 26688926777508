import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Slider from 'react-slick';
import './Announcements.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

function InslideAnnouncements() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchSlides = async () => {
      const q = query(
        collection(firestore, 'announcement_slides'),
        where('visible', '==', true)
      );
      const querySnapshot = await getDocs(q);
      const slideData = querySnapshot.docs.map(doc => doc.data());

      // Check if images exist before setting slides
      const validatedSlides = await Promise.all(slideData.map(async (slide) => {
        const imageExists = await checkImageExists(slide.url);
        return imageExists ? slide : null;
      }));

      setSlides(validatedSlides.filter(slide => slide !== null)); // Filter out null values
    };

    fetchSlides();
  }, []);

  // Function to check if image URL exists
  const checkImageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="announcements-page">
      {slides.length > 0 ? (
        <Slider {...sliderSettings}>
          {slides.map((slide, index) => (
            <div key={index} className="slide">
              <img src={slide.url} alt={slide.title} className="slide-image" />
              {
                //<p className="slide-title">{slide.title}</p>
              }
            </div>
          ))}
        </Slider>
      ) : (
        <p className="no-announcements-message">No announcements to display at the moment.</p>
      )}
    </div>
  );
}

export default InslideAnnouncements;
