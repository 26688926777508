import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../firebase';
import './EventList.css';

const EventList = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const eventsRef = ref(database, 'events');
    const unsubscribe = onValue(eventsRef, (snapshot) => {
      const eventsData = snapshot.val();
      if (eventsData) {
        const eventsArray = Object.keys(eventsData).map(key => ({
          id: key,
          ...eventsData[key]
        }));
        setEvents(eventsArray);
      } else {
        setEvents([]);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <section id="events" className="events">
      {
        events.length != 0 &&
      <h2>Upcoming Events</h2>
      }
      <div className="event-list">
        {events.map(event => (
          <div className="event-item" key={event.id}>
            <h3>{event.title}</h3>
            <p>Date: {event.date}</p>
            <p>Time: {event.time}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EventList;
