import React from 'react';
import './PrivacyPolicy.css';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: October 26, 2024</p>
      
      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to Jesus People's Parish. This Privacy Policy explains how we collect, use, and disclose your personal information when you visit our website.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>
          We collect various types of information in connection with the services we provide, including:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> such as your name, email address, and phone number.</li>
          <li><strong>Usage Data:</strong> information on how you use our website, including pages visited and time spent on the site.</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>
          We may use the information we collect for various purposes, including:
        </p>
        <ul>
          <li>To provide, operate, and maintain our website</li>
          <li>To improve and personalize your experience on our site</li>
          <li>To communicate with you, including sending updates and responding to inquiries</li>
          <li>To comply with legal obligations</li>
        </ul>
      </section>

      <section>
        <h2>Data Sharing and Disclosure</h2>
        <p>
          We may share your information with trusted third parties to help us operate our website or to comply with legal requirements. We do not sell your personal information to third parties.
        </p>
      </section>

      <section>
        <h2>Your Rights</h2>
        <p>
          You have the right to access, update, or delete the personal information we have on you. If you would like to exercise these rights, please contact us at <a href="mailto:rccgjesuspeopledagenham@gmail.com">rccgjesuspeopledagenham@gmail.com</a>.
        </p>
      </section>

      <section>
        <h2>Security of Your Information</h2>
        <p>
          We are committed to protecting your personal information. However, please be aware that no method of transmission over the Internet or method of electronic storage is 100% secure.
        </p>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
          <br />
          Email: <a href="mailto:rccgjesuspeopledagenham@gmail.com">rccgjesuspeopledagenham@gmail.com</a>
          <br />
          Phone: 020 8592 9699
        </p>
      </section>
      <Link to="/"> Return to Home</Link>
    </div>
  );
}

export default PrivacyPolicy;
