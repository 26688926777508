import React from "react";
import { Link } from "react-router-dom";

function LoginHomepage({ authUser }) {
    const isAdmin = authUser?.roles?.admin;
    const messageteam = isAdmin || authUser?.roles?.messageteam;
    const isAnnoucementteam = isAdmin || authUser?.roles?.annoucementteam;
    const isChoirteam = isAdmin || authUser?.roles?.choirteam;
    const isPrayerteam = isAdmin || authUser?.roles?.prayerteam;
    const isUsherteam = isAdmin || authUser?.roles?.usherteam;
    const isTeacher = isAdmin || authUser?.roles?.teacher;
    const isMember = isAdmin || authUser?.roles?.member;

    console.log(authUser);

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Welcome to the Dashboard</h2>
            <div style={styles.linkContainer}>
                {messageteam && (
                    <Link to="/messageteam" style={styles.link}>
                        Message Panel
                    </Link>
                )}
                {isAdmin && (
                    <Link to="/manageusers" style={styles.link}>
                        User Role Management
                    </Link>
                )}
                {isAnnoucementteam && (
                    <Link to="/edit-annoucements" style={styles.link}>
                        Edit Announcements
                    </Link>
                )}
            </div>
        </div>
    );
}

const styles = {
    container: {
        padding: "20px",
        textAlign: "center",
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
    },
    header: {
        marginBottom: "20px",
        fontSize: "24px",
        color: "#333",
    },
    linkContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
    },
    link: {
        padding: "10px 20px",
        borderRadius: "5px",
        textDecoration: "none",
        color: "#fff",
        backgroundColor: "#007bff",
        transition: "background-color 0.3s ease",
    },
    linkHover: {
        backgroundColor: "#0056b3",
    }
};

export default LoginHomepage;
