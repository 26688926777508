import React from 'react';
import { motion } from 'framer-motion';
import './MeetOurPastor.css';

const MeetOurPastor = () => {
  return (
    <div className="pastor-page">
      <motion.h1 
        className="page-title"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Meet Our Pastor
      </motion.h1>
      
      <div className="pastor-info">
        <motion.div
          className="pastor-image-placeholder"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.7 }}
        >
          {/* Add the pastor's image here later */}
          <p>Pastor's Image</p>
        </motion.div>
        
        <motion.div 
          className="info-text"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <h2>Parish Leadership</h2>
          <p><strong>Name:</strong> Paul Solademi</p>
          <p><strong>Position:</strong> New Zonal Pastor</p>
          <p><strong>Region:</strong> Region 2</p>
          <p><strong>Province:</strong> Region 2 Province 2</p>
          <p><strong>Zone:</strong> Region 2 Province 2 Zone 1</p>
          <p><strong>Worship Address:</strong> Unit 19, Midas Business Centre, Dagenham, Essex RM10 8PS</p>
        </motion.div>
      </div>
    </div>
  );
};

export default MeetOurPastor;
