import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore"; // Import Firestore

const firebaseConfig = {
  apiKey: "AIzaSyD5tT3M6owXDS_MA-pg_7hahUgVrlbLsSU",
  authDomain: "rccgjppd-b8d20.firebaseapp.com",
  projectId: "rccgjppd-b8d20",
  storageBucket: "rccgjppd-b8d20.appspot.com",
  messagingSenderId: "171123871007",
  appId: "1:171123871007:web:739c835d14e21885d4f42a",
  measurementId: "G-8GT5Z08KFX",
  databaseURL: "https://rccgjppd-b8d20-default-rtdb.europe-west1.firebasedatabase.app/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
// Initialize Firestore
export const firestore = getFirestore(app); // Add this line for Firestore
export const storage = getStorage(app);
