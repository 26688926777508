import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { ref, get } from 'firebase/database';
import { database } from '../../firebase'; // Firebase database
import { useNavigate } from 'react-router-dom';

const UserMenu = () => {
    const [userRoles, setUserRoles] = useState([]);
    const [pages, setPages] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserRoles = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = ref(database, `users/${user.uid}`);
                const snapshot = await get(userRef);
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    const roles = userData.roles || [];
                    setUserRoles(roles);

                    // Define which pages are accessible by each role
                    const pagesForRoles = {
                        admin: ['/admin', '/manage-users', '/reports'],
                        user: ['/dashboard', '/profile'],
                    };

                    // Filter pages based on user roles
                    const accessiblePages = Object.keys(pagesForRoles).reduce((acc, role) => {
                        if (roles.includes(role)) {
                            return acc.concat(pagesForRoles[role]);
                        }
                        return acc;
                    }, []);
                    setPages(accessiblePages);
                }
            }
        };

        fetchUserRoles();
    }, []);

    const handlePageClick = (page) => {
        navigate(page);
    };

    return (
        <div>
            <h2>Your Accessible Pages</h2>
            <ul>
                {pages.map(page => (
                    <li key={page} onClick={() => handlePageClick(page)} style={{ cursor: 'pointer' }}>
                        {page}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserMenu;
