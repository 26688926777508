import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase'; // Ensure this imports your Firebase auth instance
import './ForgotPassword.css'; // Import your CSS file

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        setMessage(null); // Reset any previous messages
        setError(null); // Reset any previous errors

        try {
            // Send password reset email
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent! Please check your inbox.');
        } catch (error) {
            setError('Failed to send password reset email. Please try again.');
            console.error('Password reset error:', error);
        }
    };

    return (
        <div className='forgot-password-container'>
            <form onSubmit={handlePasswordReset}>
                <h1>Forgot Password</h1>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Reset Password</button>

                {message && <p style={{ color: 'green' }}>{message}</p>}
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <p>
                    <a href="/worker">Go Back</a>
                </p>
            </form>
        </div>
    );
};

export default ForgotPassword;
