import React, { useState, useEffect } from 'react';
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import './SignUpPage.css'; // Custom CSS for styling
import logo from "../../Assets/RCCG_logo.png"; // Logo image
import { database } from '../../firebase'; // Importing Firebase database
import { ref, get } from 'firebase/database'; // Firebase Realtime Database methods

const SignUpPage = () => {
  const [showSignIn, setShowSignIn] = useState(true);
  const [allowSignUps, setAllowSignUps] = useState(true); // State to manage sign-up allowance

  // Fetch the allowSignUps setting from the database
  useEffect(() => {
    const fetchSignUpSetting = async () => {
      const settingRef = ref(database, 'settings/allowSignUps');
      const snapshot = await get(settingRef);
      if (snapshot.exists()) {
        setAllowSignUps(snapshot.val());
      }
    };
    fetchSignUpSetting();
  }, []);

  // Handles click events on the image
  const handleClick = () => {
    setShowSignIn(!showSignIn);
  };

  return (
    <div className="signup-page">
      <div className="logo-container">
        <img
          src={logo}
          onClick={handleClick}
          className="logo"
          alt="Logo"
        />
      </div>

      <div className="form-container">


        
        {
          showSignIn ? (
            <SignIn />
          ) : (
            allowSignUps ? 
            <SignUp />
            :
            <div className="disabled-signup-message">
            <h2>Sign-ups are currently disabled.</h2>
            <p>Please check back later.</p>
          </div>
          )
          }
      </div>
    </div>
  );
};

export default SignUpPage;
