import React, { useState, useEffect } from 'react';
import { ref, get, update, remove } from 'firebase/database';
import { database } from '../../firebase'; // Firebase database

const ManageUserRoles = () => {
    const [users, setUsers] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [newRole, setNewRole] = useState(''); // For adding new roles
    const [editingRole, setEditingRole] = useState(null); // For editing roles
    const [editedRole, setEditedRole] = useState(''); // For the updated role name

    const ADMIN_ROLE = 'Admin'; // Constant for the Admin role

    useEffect(() => {
        const fetchUsersAndRoles = async () => {
            // Fetch users
            const usersRef = ref(database, 'users');
            const snapshot = await get(usersRef);
            const usersData = snapshot.val();
            setUsers(usersData ? Object.entries(usersData) : []);

            // Fetch available roles
            const rolesRef = ref(database, 'roles');
            const rolesSnapshot = await get(rolesRef);
            const rolesData = rolesSnapshot.val();
            if (rolesData) {
                setAvailableRoles(Object.keys(rolesData));
            }
        };

        fetchUsersAndRoles();
    }, []);

    const handleRoleChange = (userId, role, isChecked) => {
        const userRef = ref(database, `users/${userId}`);
        
        get(userRef).then((snapshot) => {
            const userData = snapshot.val();
            const userRoles = userData.roles || [];
    
            let updatedRoles;
    
            if (isChecked) {
                if (role === ADMIN_ROLE) {
                    // Admin role gives all roles, adding both admin and available roles
                    updatedRoles = [...new Set([...userRoles.map(r => r.name), ADMIN_ROLE, ...availableRoles])];
                } else {
                    // Add selected role normally
                    updatedRoles = [...new Set([...userRoles.map(r => r.name), role])];
                }
            } else {
                if (role === ADMIN_ROLE) {
                    // Remove only Admin role, leave other roles intact
                    updatedRoles = userRoles.filter(r => r.name !== ADMIN_ROLE).map(r => r.name);
                } else {
                    // Remove the selected role
                    updatedRoles = userRoles.filter(r => r.name !== role).map(r => r.name);
                }
            }
    
            // Remove undefined or invalid roles
            updatedRoles = updatedRoles.filter(role => role && typeof role === 'string');
    
            // Update Firebase with valid roles
            update(userRef, { roles: updatedRoles.map(role => ({ name: role })) })
            .catch((error) => {
                console.error("Error updating roles:", error);
            });
        }).catch((error) => {
            console.error("Error fetching user data:", error);
        });
    };
    
    

    const handleAddRole = async () => {
        if (newRole.trim() && newRole !== ADMIN_ROLE) { // Prevent adding "Admin" role again
            const rolesRef = ref(database, 'roles');
            await update(rolesRef, { [newRole]: true }); // Add new role to Firebase
            setAvailableRoles(prev => [...prev, newRole]);
            setNewRole(''); // Reset input field
        }
    };

    const handleEditRole = (role) => {
        setEditingRole(role);
        setEditedRole(role);
    };

    const handleUpdateRole = async () => {
        const rolesRef = ref(database, 'roles');
        if (editedRole && editingRole && editingRole !== ADMIN_ROLE) { // Prevent editing the "Admin" role
            await update(rolesRef, { [editedRole]: true }); // Add the updated role
            await remove(ref(database, `roles/${editingRole}`)); // Remove the old role
            setAvailableRoles(prev => prev.map(r => (r === editingRole ? editedRole : r)));
            setEditingRole(null);
            setEditedRole('');
        }
    };

    const handleDeleteRole = async (role) => {
        if (role !== ADMIN_ROLE) { // Prevent deletion of "Admin" role
            const rolesRef = ref(database, `roles/${role}`);
            await remove(rolesRef); // Remove role from Firebase
            setAvailableRoles(prev => prev.filter(r => r !== role));
        }
    };

    return (
        <div>
            <h2>Manage User Roles</h2>

            {/* Role management section */}
            <div>
                <h3>Add New Role</h3>
                <input
                    type="text"
                    value={newRole}
                    onChange={(e) => setNewRole(e.target.value)}
                    placeholder="Enter new role"
                />
                <button onClick={handleAddRole}>Add Role</button>
            </div>

            <div>
                <h3>Available Roles</h3>
                {availableRoles.map(role => (
                    <div key={role}>
                        {editingRole === role ? (
                            <div>
                                <input
                                    type="text"
                                    value={editedRole}
                                    onChange={(e) => setEditedRole(e.target.value)}
                                />
                                <button onClick={handleUpdateRole}>Update Role</button>
                                <button onClick={() => setEditingRole(null)}>Cancel</button>
                            </div>
                        ) : (
                            <div>
                                <span>{role}</span>
                                {role !== ADMIN_ROLE && (
                                    <>
                                        <button onClick={() => handleEditRole(role)}>Edit</button>
                                        <button onClick={() => handleDeleteRole(role)}>Delete</button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* User roles management section */}
            {users.map(([userId, userData]) => (
                <div key={userId}>
                    <h3>{userData.firstName} {userData.lastName}</h3>
                    <p>Email: {userData.email}</p>
                    <div>
                        {availableRoles.map(role => (
                            <div key={role}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={userData.roles?.some(r => r.name === role) || false}
                                        onChange={(e) => handleRoleChange(userId, role, e.target.checked)}
                                    />
                                    {role}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ManageUserRoles;
