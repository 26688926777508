import React from "react";
import { motion } from "framer-motion";

function DonationPage() {
    return (
        <div style={styles.container}>
            <motion.div 
                style={styles.content}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <h1 style={styles.header}>Support Our Ministry</h1>
                <p style={styles.description}>
                    Your generous donations help us continue our mission. Thank you for supporting the church and the community.
                </p>

                <motion.div
                    style={styles.donationInfo}
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                >
                    <h2 style={styles.subheader}>Bank Transfer Details</h2>
                    <p style={styles.text}>
                        <strong>Sort Code:</strong> 82-60-13
                    </p>
                    <p style={styles.text}>
                        <strong>Account Number:</strong> 20144583
                    </p>
                </motion.div>

                <motion.button
                    style={styles.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    Donate Now
                </motion.button>
            </motion.div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "40px",
        backgroundColor: "#f4f7fa",
        minHeight: "100vh",
    },
    content: {
        textAlign: "center",
        maxWidth: "500px",
        padding: "20px",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    },
    header: {
        fontSize: "32px",
        color: "#333",
        marginBottom: "20px",
    },
    description: {
        fontSize: "18px",
        color: "#555",
        marginBottom: "30px",
    },
    donationInfo: {
        backgroundColor: "#e8f1ff",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "30px",
    },
    subheader: {
        fontSize: "24px",
        color: "#007bff",
        marginBottom: "10px",
    },
    text: {
        fontSize: "16px",
        color: "#333",
        margin: "5px 0",
    },
    button: {
        fontSize: "18px",
        padding: "10px 20px",
        color: "#fff",
        backgroundColor: "#007bff",
        border: "none",
        borderRadius: "8px",
        cursor: "pointer",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.3s ease",
    },
};

export default DonationPage;
