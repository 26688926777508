import React from 'react';
import './Location.css';

const Location = () => {
  return (
    <div className="location-container">
      <div className="location-details">
        <h2 className="location-title">Our Location</h2>
        <p className="location-address">
          RCCG Jesus People's Parish Dagenham<br />
          6a Wantz Rd, Dagenham RM10 8PY, UK
        </p>
        <p className="location-hours">
          <strong>Service Hours:</strong><br />
          Sunday: 10:15 am - 1:00 pm<br />
          Wednesday Bible Study: 6:00 pm - 8:00 pm (Online)<br />
          Friday Prayer Meeting: 6:00 pm - 8:00 pm
        </p>
        <a
          href="https://www.google.com/maps/place/6a+Wantz+Rd,+Dagenham+RM10+8PY,+UK"
          target="_blank"
          rel="noopener noreferrer"
          className="map-button"
        >
          Get Directions
        </a>
      </div>
      <div className="map-container">
        <iframe
          title="Church Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1982.2628591988097!2d0.1515103154324267!3d51.55713257963867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8b152903f8e43%3A0x18ccf1ae9dbcd4ab!2s6a%20Wantz%20Rd%2C%20Dagenham%20RM10%208PY%2C%20UK!5e0!3m2!1sen!2sus!4v1697031826483!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Location;
