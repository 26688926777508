import React, { useState } from 'react';
//import logo from '../../Assets/RCCG_logo.png';
import logo from '../../Assets/android-chrome-512x512.png';
import './NavBar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="navbar">
      <div className="logo-container">
        <img src={logo} alt="RCCG Logo" className="logo" />
        <div className="tagline-container">
          <h1 className="site-title">The Redeemed Christian Church of God</h1>
          <p className="tagline">Seek | Save | Disciple</p>
        </div>
      </div>
      <nav className={`nav-menu ${menuOpen ? 'open' : ''}`}>
        <ul className="nav-links">
          <li><a href="/">Home</a></li>          
          <li><a href="/meet-our-pastor">Meet Our Pastor</a></li>
          <li><a href="/worker">Worker</a></li>
          <li><a href="/announcements">Announcements</a></li>
          <li><a href="/contact-us">Contact</a></li>
          <li><a href="/donation">Donate</a></li>
        </ul>
      </nav>
      <button className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? '✕' : '☰'}
      </button>
    </header>
  );
};

export default Navbar;
