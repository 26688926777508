import React, { useEffect } from 'react';
import './ZoomPage.css';

const ZoomPage = () => {
  useEffect(() => {
    const header = document.querySelector('.zoom-header');
    const info = document.querySelector('.zoom-info');
    const footer = document.querySelector('.zoom-footer');

    header.classList.add('fade-in');
    info.classList.add('slide-in');
    footer.classList.add('fade-in');
  }, []);

  return (
    <div className="zoom-page">
      <header className="zoom-header">
        <h1>Join Our Live Service</h1>
        <p>Welcome! Join our weekly services via Zoom by clicking the button below.</p>
      </header>
      <main className="zoom-main">
        <div className="zoom-info">
          <h2>Weekly Service Schedule</h2>
          <ul>
            <li><strong>Bible Study:</strong> Wednesdays, 6 pm - 8 pm (Online only)</li>
            <li><strong>Prayer Meeting:</strong> Fridays, 6 pm - 8 pm (In church)</li>
            <li><strong>Sunday Service:</strong> Sundays, 10:15 am - 1 pm</li>
          </ul>
          <a href="https://us04web.zoom.us/j/6368352885" target="_blank" rel="noopener noreferrer" className="zoom-button">
            Join Zoom Meeting
          </a>
        </div>
      </main>
      <footer className="zoom-footer">
        {
          //<p>&copy; 2024 Jesus People's Parish. All rights reserved.</p>
        }
      </footer>
    </div>
  );
};

export default ZoomPage;
