import React, { useState, useEffect } from 'react';
import { ref, get, update, remove, set } from 'firebase/database';
import { database } from '../../firebase';
import './UserManagement.css';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch users from the Realtime Database
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await get(ref(database, 'users'));
        if (snapshot.exists()) {
          const usersData = snapshot.val();
          const formattedUsers = Object.keys(usersData).map(userId => ({
            id: userId,
            ...usersData[userId],
          }));
          setUsers(formattedUsers);
        } else {
          setUsers([]);
        }
      } catch (err) {
        setError("Failed to load users.");
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const updateUserRole = async (userId, role, value) => {
    try {
      await update(ref(database, `users/${userId}/roles`), { [role]: value });
      alert(`Role "${role}" updated for user ID: ${userId}`);
      updateUserList(userId, { [role]: value });
    } catch {
      setError("Failed to update user role.");
    }
  };

  const deleteUserRole = async (userId, role) => {
    try {
      await remove(ref(database, `users/${userId}/roles/${role}`));
      alert(`Role "${role}" removed from user ID: ${userId}`);
      updateUserList(userId, { [role]: undefined });
    } catch {
      setError("Failed to delete user role.");
    }
  };

  const addUserRole = async (userId, role) => {
    try {
      await set(ref(database, `users/${userId}/roles/${role}`), false);
      alert(`Role "${role}" added for user ID: ${userId}`);
      updateUserList(userId, { [role]: false });
    } catch {
      setError("Failed to add user role.");
    }
  };

  const updateUserList = (userId, roleUpdate) => {
    setUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === userId ? { ...user, roles: { ...user.roles, ...roleUpdate } } : user
      )
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (users.length === 0) return <div>No users found.</div>;

  return (
    <div>
      <h1>User Management</h1>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Roles</th>
            <th>Add Role</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>
                {Object.entries(user.roles || {}).map(([role, value]) => (
                  <div key={role}>
                    <input
                      type="checkbox"
                      checked={value}
                      onChange={(e) => updateUserRole(user.id, role, e.target.checked)}
                    />
                    {role}
                    <button onClick={() => deleteUserRole(user.id, role)}>Delete Role</button>
                  </div>
                ))}
              </td>
              <td>
                <input
                  type="text"
                  placeholder="New role"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.target.value) {
                      addUserRole(user.id, e.target.value);
                      e.target.value = '';
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserManagement;
