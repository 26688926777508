import React, { useEffect, useState } from 'react';
import { collection, getDocs, Timestamp } from 'firebase/firestore';
import { firestore as database } from '../../firebase'; // Ensure this imports your Firestore instance
import './NewComerManager.css'; // Import the CSS file

const NewComerManager = () => {
  const [newComers, setNewComers] = useState([]);
  const [customMessage, setCustomMessage] = useState('');

  useEffect(() => {
    const fetchNewComers = async () => {
      try {
        const membersCollection = collection(database, 'members');
        const membersSnapshot = await getDocs(membersCollection);
        const today = new Date();

        const newComersArray = membersSnapshot.docs
          .map(doc => {
            const member = {
              id: doc.id,
              ...doc.data()
            };

            // Convert date_attended to Date if it's a Timestamp
            if (member.date_attended && member.date_attended instanceof Timestamp) {
              member.date_attended = member.date_attended.toDate();
            }

            return member;
          })
          .filter(member => {
            const attendedDate = new Date(member.date_attended);
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(today.getDate() - 7);

            // Filter new comers who attended only once within the last week
            return member.dates_attended && member.dates_attended.length === 1 && attendedDate >= oneWeekAgo;
          });

        setNewComers(newComersArray);
      } catch (error) {
        console.error('Error fetching new comers:', error);
      }
    };

    fetchNewComers();
  }, []);

  const handleSendMessage = (member) => {
    const message = customMessage || `Welcome, ${member.first_name}! We're glad you visited.`;
    
    // Add the message to the Firestore sending stack
    database.collection('sending_stack').add({
      text: message,
      member
    });

    console.log(`Message sent to ${member.first_name}:`, message);
  };

  return (
    <div className="new-comer-manager">
      <h2>New Comer Manager</h2>
      <h3>New Comers from the Last Week</h3>
      {newComers.length === 0 ? (
        <p>No new comers this week.</p>
      ) : (
        <ul className="new-comer-list">
          {newComers.map(member => (
            <li key={member.id} className="new-comer-item">
              {member.first_name} {member.last_name} - Attended on {new Date(member.date_attended).toLocaleDateString()}
              <br />
              <input 
                type="text" 
                placeholder="Enter custom message" 
                value={customMessage} 
                onChange={e => setCustomMessage(e.target.value)} 
                className="custom-message-input"
              />
              <button className="send-message-button" onClick={() => handleSendMessage(member)}>
                Send Message
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NewComerManager;
