import React from 'react';
import './ContactInfo.css';

const ContactInfo = () => (
  <section id="about" className="info-container">
    <h2>Contact Information</h2>
    <p><strong>Address:</strong> 6a Wantz Rd, Dagenham RM10 8PY</p>
    <p><strong>Phone:</strong> 020 8592 9699</p>
    <p><strong>Email:</strong> <a href="mailto:rccgjesuspeopledagenham@gmail.com">rccgjesuspeopledagenham@gmail.com</a></p>
    <p><strong>Facebook:</strong> <a href="https://www.facebook.com/p/Jesus-People-Parish-Dagenham-RCCG-100064852414599/?_rdr" target="_blank" rel="noopener noreferrer">Visit our page</a></p>
  </section>
);

export default ContactInfo;
