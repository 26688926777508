import React, { useEffect, useState } from 'react';
import { collection, getDocs, Timestamp } from 'firebase/firestore';
import { firestore as database } from '../../firebase'; // Ensure this imports your Firestore instance
import './AttendanceManager.css'; // Import the CSS file

const AttendanceManager = () => {
  const [members, setMembers] = useState([]);
  const [customMessage, setCustomMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // For tracking the current page
  const [itemsPerPage, setItemsPerPage] = useState(5); // For tracking items per page

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const membersCollection = collection(database, 'members');
        const membersSnapshot = await getDocs(membersCollection);
        const membersArray = membersSnapshot.docs.map(doc => {
          const member = {
            id: doc.id,
            ...doc.data()
          };
          
          // Ensure `dates_attended` is processed correctly
          if (member.dates_attended && Array.isArray(member.dates_attended) && member.dates_attended.length > 0) {
            // Convert each date to a JS Date object if it's a Firestore Timestamp
            member.dates_attended = member.dates_attended.map(date => 
              date instanceof Timestamp ? date.toDate() : new Date(date)
            );
            // Use the last attended date for sorting and display (assuming it's the most recent)
            member.last_attended = member.dates_attended[member.dates_attended.length - 1];
          }

          return member;
        }).filter(member => member.last_attended); // Only members with non-empty dates_attended

        setMembers(membersArray);
        console.log("Members Array Length:", membersArray.length);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, []);

  const handleSendMessage = (member) => {
    const message = customMessage || `We missed you, ${member.first_name}! Hope to see you again soon!`;
    
    // Add the message to the Firestore sending stack (or implement custom logic for sending)
    database.collection('sending_stack').add({
      text: message,
      member
    });
    
    console.log(`Message sent to ${member.first_name}:`, message);
  };

  // Sort members by who hasn't been seen for the longest time
  const sortedMembers = members.sort((a, b) => {
    return new Date(a.last_attended) - new Date(b.last_attended); // Ascending order (oldest date first)
  });

  // Pagination logic: calculate the displayed members for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedMembers = sortedMembers.slice(startIndex, endIndex);

  // Calculate total pages
  const totalPages = Math.ceil(sortedMembers.length / itemsPerPage);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page after changing items per page
  };

  return (
    <div>
      <h2>Attendance Manager</h2>
      <h3>Members who haven't attended recently</h3>

      {/* Dropdown to select number of items per page */}
      <label>
        Show: 
        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
        </select> members per page
      </label>

      {sortedMembers.length === 0 ? (
        <p>Everyone has attended recently.</p>
      ) : (
        <ul>
          {paginatedMembers.map(member => (
            <li key={member.id}>
              {member.first_name} {member.last_name} - Last attended on {new Date(member.last_attended).toLocaleDateString()}
              <br />
              <input 
                type="text" 
                placeholder="Enter custom message" 
                value={customMessage} 
                onChange={e => setCustomMessage(e.target.value)} 
              />
              <button onClick={() => handleSendMessage(member)}>
                Send Message
              </button>
            </li>
          ))}
        </ul>
      )}

      {/* Pagination controls */}
      <div>
        <button 
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span> Page {currentPage} of {totalPages} </span>
        <button 
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AttendanceManager;
