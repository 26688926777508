import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { firestore as database } from '../../firebase'; // Ensure this imports your Firestore instance
import './SendingStackManager.css'; // Import the CSS file

const SendingStackManager = () => {
  const [sendingStack, setSendingStack] = useState([]);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [updatedMessage, setUpdatedMessage] = useState('');
  const [showDeleteAllConfirm, setShowDeleteAllConfirm] = useState(false); // New state for delete all confirmation

  // Fetch all messages from sending stack
  useEffect(() => {
    const fetchSendingStack = async () => {
      try {
        const sendingStackCollection = collection(database, 'sending_stack');
        const snapshot = await getDocs(sendingStackCollection);
        const stackArray = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSendingStack(stackArray);
      } catch (error) {
        console.error("Error fetching sending stack:", error);
      }
    };

    fetchSendingStack();
  }, []);

  // Handle message editing
  const handleEdit = (id, message) => {
    setEditingMessageId(id);
    setUpdatedMessage(message);
  };

  // Save edited message
  const handleSave = async (id) => {
    try {
      const messageDoc = doc(database, 'sending_stack', id);
      await updateDoc(messageDoc, { text: updatedMessage });
      setSendingStack(prevStack =>
        prevStack.map(item => (item.id === id ? { ...item, text: updatedMessage } : item))
      );
      setEditingMessageId(null);
      setUpdatedMessage('');
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  // Cancel edit
  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setUpdatedMessage('');
  };

  // Handle individual message deletion
  const handleDelete = async (id) => {
    try {
      const messageDoc = doc(database, 'sending_stack', id);
      await deleteDoc(messageDoc);
      setSendingStack(prevStack => prevStack.filter(item => item.id !== id));
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  // Handle deleting all messages
  const handleDeleteAll = async () => {
    try {
      const sendingStackCollection = collection(database, 'sending_stack');
      const snapshot = await getDocs(sendingStackCollection);
      const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));

      await Promise.all(deletePromises);
      setSendingStack([]);
      setShowDeleteAllConfirm(false); // Hide confirmation after delete
    } catch (error) {
      console.error("Error deleting all messages:", error);
    }
  };

  return (
    <div className="sending-stack-container">
      <h2>Sending Stack Manager</h2>
      
      {/* Delete All Button and Confirmation Dialog */}
      {showDeleteAllConfirm ? (
        <div className="delete-confirmation">
          <p>Are you sure you want to delete all messages?</p>
          <button onClick={handleDeleteAll} className="delete-all-btn">
            Yes, Delete All
          </button>
          <button onClick={() => setShowDeleteAllConfirm(false)} className="cancel-btn">
            Cancel
          </button>
        </div>
      ) : (
        <button onClick={() => setShowDeleteAllConfirm(true)} className="delete-all-btn">
          Delete All Messages
        </button>
      )}
      
      {/* Display messages in sending stack */}
      {sendingStack.length === 0 ? (
        <p>No messages in the sending stack.</p>
      ) : (
        <ul className="sending-stack-list">
          {sendingStack.map(message => (
            <li key={message.id} className="message-item">
              <strong>Recipient:</strong> {message.member.first_name} {message.member.last_name} - {message.member.phone_number}
              <br />
              <strong>Message:</strong>
              {editingMessageId === message.id ? (
                <>
                  <textarea
                    value={updatedMessage}
                    onChange={(e) => setUpdatedMessage(e.target.value)}
                    className="edit-textarea"
                  />
                  <button onClick={() => handleSave(message.id)} className="save-btn">Save</button>
                  <button onClick={handleCancelEdit} className="cancel-btn">Cancel</button>
                </>
              ) : (
                <>
                  {message.text}
                  <button onClick={() => handleEdit(message.id, message.text)} className="edit-btn">
                    Edit
                  </button>
                </>
              )}
              <button onClick={() => handleDelete(message.id)} className="delete-btn">
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SendingStackManager;
