// AdminControl.js
import React, { useState, useEffect } from 'react';
import { database } from '../../firebase'; // Import your Firebase database
import { ref, get, set } from 'firebase/database'; // Firebase methods

const AdminControl = () => {
  const [allowSignUps, setAllowSignUps] = useState(true); // State for sign-up setting
  const [loading, setLoading] = useState(true); // State for loading indicator

  // Fetch the current setting from Firebase
  useEffect(() => {
    const fetchSignUpSetting = async () => {
      const settingRef = ref(database, 'settings/allowSignUps');
      const snapshot = await get(settingRef);
      if (snapshot.exists()) {
        setAllowSignUps(snapshot.val());
      }
      setLoading(false);
    };
    fetchSignUpSetting();
  }, []);

  // Toggle the allowSignUps setting in Firebase
  const toggleSignUpSetting = async () => {
    const newValue = !allowSignUps; // Toggle the current value
    await set(ref(database, 'settings/allowSignUps'), newValue);
    setAllowSignUps(newValue); // Update local state
  };

  if (loading) {
    return <p>Loading...</p>; // Loading indicator while fetching data
  }

  return (
    <div className="admin-control-container">
      <h1>Admin Control</h1>
      <div>
        <h2>Allow Sign-Ups:</h2>
        <p>{allowSignUps ? 'Enabled' : 'Disabled'}</p>
        <button onClick={toggleSignUpSetting}>
          {allowSignUps ? 'Disable Sign-Ups' : 'Enable Sign-Ups'}
        </button>
      </div>
    </div>
  );
};

export default AdminControl;
