import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore as database } from '../../firebase'; // Ensure this imports your Firestore instance
import './MessageLogs.css'; // Import the CSS file

const MessageLogs = () => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [failedMessages, setFailedMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(10); // Default to 10 logs per page

  // Fetch message history and failed sends
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        // Fetch successful messages
        const historyCollection = collection(database, 'message_history');
        const historySnapshot = await getDocs(historyCollection);
        const historyArray = historySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Fetch failed messages
        const failedCollection = collection(database, 'failed_sends');
        const failedSnapshot = await getDocs(failedCollection);
        const failedArray = failedSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setMessageHistory(historyArray);
        setFailedMessages(failedArray);
      } catch (error) {
        console.error("Error fetching message logs:", error);
      }
    };

    fetchLogs();
  }, []);

  // Handle search functionality
  const filteredHistory = messageHistory.filter(log =>
    log.recipient_full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    log.recipient_phone.includes(searchQuery)
  );

  const filteredFailed = failedMessages.filter(log =>
    log.recipient_full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    log.recipient_phone.includes(searchQuery)
  );

  // Pagination logic
  const totalHistoryPages = Math.ceil(filteredHistory.length / logsPerPage);
  const totalFailedPages = Math.ceil(filteredFailed.length / logsPerPage);
  
  const currentHistoryLogs = filteredHistory.slice((currentPage - 1) * logsPerPage, currentPage * logsPerPage);
  const currentFailedLogs = filteredFailed.slice((currentPage - 1) * logsPerPage, currentPage * logsPerPage);

  return (
    <div className="message-logs">
      <h2>Message Logs</h2>

      <input
        type="text"
        placeholder="Search by name or phone number"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />

      <label className="logs-per-page-label">
        Logs per page: 
        <select value={logsPerPage} onChange={(e) => setLogsPerPage(Number(e.target.value))} className="logs-per-page-select">
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </select>
      </label>

      <h3>Sent Messages</h3>
      {currentHistoryLogs.length === 0 ? (
        <p>No sent messages found.</p>
      ) : (
        <ul className="message-list">
          {currentHistoryLogs.map(log => (
            <li key={log.id} className="message-item">
              <strong>Recipient:</strong> {log.recipient_full_name} - {log.recipient_phone}
              <br />
              <strong>Message:</strong> {log.message}
              <br />
              <strong>Date Sent:</strong> {new Date(log.date_sent).toLocaleString()}
            </li>
          ))}
        </ul>
      )}

      <div className="pagination">
        <button className="pagination-button" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
          Previous
        </button>
        <span> Page {currentPage} of {totalHistoryPages} </span>
        <button className="pagination-button" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalHistoryPages))} disabled={currentPage === totalHistoryPages}>
          Next
        </button>
      </div>

      <h3>Failed Messages</h3>
      {currentFailedLogs.length === 0 ? (
        <p>No failed messages found.</p>
      ) : (
        <ul className="message-list">
          {currentFailedLogs.map(log => (
            <li key={log.id} className="message-item">
              <strong>Recipient:</strong> {log.recipient_full_name} - {log.recipient_phone}
              <br />
              <strong>Message:</strong> {log.message}
              <br />
              <strong>Date Sent:</strong> {new Date(log.date_sent).toLocaleString()}
              <br />
              <strong>Status:</strong> Failed
            </li>
          ))}
        </ul>
      )}

      <div className="pagination">
        <button className="pagination-button" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
          Previous
        </button>
        <span> Page {currentPage} of {totalFailedPages} </span>
        <button className="pagination-button" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalFailedPages))} disabled={currentPage === totalFailedPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default MessageLogs;
