import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './components/NavBar/Navbar';
import AuthDetails from './components/Auth/AuthDetails';
import ForgotPassword from './components/Auth/ForgotPassword';
import HomePage from './components/Homepage/HomePage';
import ContactUs from './components/ContactUs/ContactUs';
import Announcements from './components/Announcements/Announcements';
import Footer from './Footer';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import AdminSlides from './components/Announcements/AdminSlides';
import MeetOurPastor from './components/MeetOurPastor/MeetOurPastor';
import MessageHomepage from './components/MessageTeam/MessageHomepage';
import AdminControl from './components/Admin/AdminControl';
import UserManagement from './components/Admin/UserManagement';
import DonationPage from './components/DonationPage/DonationPage';




function App() {
  return (
      <div className="App">
        {
          <NavBar />
        }
        <div className="main-content">
          <Routes basename="/">
            <Route path="/" element={<HomePage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/worker" element={<AuthDetails />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />            
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/meet-our-pastor" element={<MeetOurPastor/>} />
            <Route path="/slides" element={<AdminSlides/>} />
            <Route path="/messageteam" element={<MessageHomepage/>} />
            <Route path="/edit-annoucements" element={<AdminSlides/>} />
            <Route path="/manageusers" element={<UserManagement/>} />
            <Route path="/allow-new-signups" element={<AdminControl/>} />
            <Route path="/donation" element={<DonationPage/>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer/>
      </div>
  );
}

export default App;
