import React, { useState, useEffect } from 'react';
import { ref, set, get, remove } from 'firebase/database';
import { database } from '../../firebase';

const AdminAnnouncements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [newAnnouncement, setNewAnnouncement] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editAnnouncementId, setEditAnnouncementId] = useState('');
    
    useEffect(() => {
        const fetchAnnouncements = async () => {
            const announcementsRef = ref(database, 'announcements');
            const snapshot = await get(announcementsRef);
            const data = snapshot.val();
            setAnnouncements(data ? Object.entries(data) : []); // Get entries for editing
        };

        fetchAnnouncements();
    }, []);

    const handleAddAnnouncement = async () => {
        if (newAnnouncement) {
            const announcementsRef = ref(database, `announcements/${new Date().getTime()}`); // Use timestamp as a unique ID
            await set(announcementsRef, { text: newAnnouncement });
            setNewAnnouncement(''); // Clear input
            await fetchAnnouncements(); // Fetch announcements again
        }
    };

    const handleEditAnnouncement = (id, currentText) => {
        setEditMode(true);
        setEditAnnouncementId(id);
        setNewAnnouncement(currentText);
    };

    const handleUpdateAnnouncement = async () => {
        if (editAnnouncementId && newAnnouncement) {
            const announcementsRef = ref(database, `announcements/${editAnnouncementId}`);
            await set(announcementsRef, { text: newAnnouncement });
            setNewAnnouncement(''); // Clear input
            setEditMode(false);
            setEditAnnouncementId('');
            await fetchAnnouncements(); // Fetch announcements again
        }
    };

    const handleDeleteAnnouncement = async (id) => {
        await remove(ref(database, `announcements/${id}`));
        await fetchAnnouncements(); // Fetch announcements again
    };

    const fetchAnnouncements = async () => {
        const announcementsRef = ref(database, 'announcements');
        const snapshot = await get(announcementsRef);
        const data = snapshot.val();
        setAnnouncements(data ? Object.entries(data) : []); // Get entries for editing
    };

    return (
        <div className="admin-announcements">
            <h1>Admin Announcements</h1>
            <input
                type="text"
                placeholder="New Announcement"
                value={newAnnouncement}
                onChange={(e) => setNewAnnouncement(e.target.value)}
            />
            {editMode ? (
                <button onClick={handleUpdateAnnouncement}>Update Announcement</button>
            ) : (
                <button onClick={handleAddAnnouncement}>Add Announcement</button>
            )}

            <h2>Current Announcements</h2>
            <ul>
                {announcements.map(([key, announcement]) => (
                    <li key={key}>
                        {announcement.text}
                        <button onClick={() => handleEditAnnouncement(key, announcement.text)}>Edit</button>
                        <button onClick={() => handleDeleteAnnouncement(key)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminAnnouncements;
