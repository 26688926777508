import React, { useEffect, useState } from 'react';
import { collection, getDocs, Timestamp } from 'firebase/firestore';
import { firestore as database } from '../../firebase'; // Ensure this imports your Firestore instance
import './BirthdayManager.css'; // Import a CSS file for custom styles

const BirthdayManager = () => {
  const [members, setMembers] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true); // Assume default is enabled

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const membersCollection = collection(database, 'members');
        const membersSnapshot = await getDocs(membersCollection);
        const membersArray = membersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMembers(membersArray);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, []);

  const toggleBirthdayMessages = () => {
    setIsEnabled(!isEnabled);
  };

  // Filter members who have birthdays this month
  const filteredMembers = members.filter(member => {
    const dob = member.DOB instanceof Timestamp 
      ? member.DOB.toDate() 
      : new Date(member.DOB);
    // Check if the birthday is in the current month
    return dob.getMonth() === new Date().getMonth();
  });

  // Sort filtered members by birthday date
  const sortedMembers = filteredMembers.sort((a, b) => {
    const dobA = a.DOB instanceof Timestamp ? a.DOB.toDate() : new Date(a.DOB);
    const dobB = b.DOB instanceof Timestamp ? b.DOB.toDate() : new Date(b.DOB);
    return dobA.getDate() - dobB.getDate();
  });

  // Find today and the next birthday
  const today = new Date();
  const todayBirthday = sortedMembers.find(member => {
    const dob = member.DOB instanceof Timestamp ? member.DOB.toDate() : new Date(member.DOB);
    return dob.getDate() === today.getDate() && dob.getMonth() === today.getMonth();
  });

  const nextBirthday = sortedMembers.find(member => {
    const dob = member.DOB instanceof Timestamp ? member.DOB.toDate() : new Date(member.DOB);
    return dob > today; // Find the next birthday after today
  });

  return (
    <div className="birthday-manager">
      <h2>🎉 Birthday Manager 🎉</h2>
      <button onClick={toggleBirthdayMessages}>
        {isEnabled ? "Disable Birthday Messages" : "Enable Birthday Messages"}
      </button>
      <h3>Members with Birthdays This Month</h3>
      {sortedMembers.length === 0 ? (
        <p>No birthdays this month.</p>
      ) : (
        <ul>
          {sortedMembers.map(member => {
            const dob = member.DOB instanceof Timestamp ? member.DOB.toDate() : new Date(member.DOB);
            const isToday = dob.getDate() === today.getDate() && dob.getMonth() === today.getMonth();
            const isNextBirthday = nextBirthday && nextBirthday.id === member.id;

            return (
              <li key={member.id} className={`member ${isToday ? 'today-birthday' : ''} ${isNextBirthday ? 'next-birthday' : ''}`}>
                {member.first_name} {member.last_name} - {dob.toLocaleDateString(undefined, { day: 'numeric', month: 'long' })}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default BirthdayManager;
