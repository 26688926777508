import React from 'react';
import IntroSection from './IntroSection';
import EventList from './EventList';
import ContactInfo from './ContactInfo';
import DonateSection from './DonateSection';
import './HomePage.css';
import ZoomPage from '../ZoomSettings/ZoomPage';
import Location from './Location';
import InslideAnnouncements from '../Announcements/InslideAccountments';

const HomePage = () => (
  <div className="homepage">
    <IntroSection />
    <ZoomPage/>
    <InslideAnnouncements/>
    <EventList />
    <ContactInfo />
    <Location/>
    <DonateSection />
  </div>
);

export default HomePage;
